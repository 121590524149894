import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FibChart from "component/Chart/FibChart";
import FibSlider from 'component/Slider/FibSlider';
import { Grid, Typography,Badge } from '@mui/material';
import { Close } from '@mui/icons-material';
import FormatToIntQty from 'utils/FormatToIntQty';
import FormatToIntCurrency from 'utils/FormatToIntCurrency';
import NoDataImg from 'assets/chr_NoData.png'
import { Add } from '@mui/icons-material';
const style = {
    position: 'absolute',
    display:'block',
    top: {xs: '0%',sm:'50%'},
    left:{xs:'0',sm:'50%'},
    transform:{xs: '',sm:'translate(-50%, -50%)'} ,
    maxWidth:{xs:'100vh',} ,
    width:{xs:"100vw",sm:'95%'},
    height:{xs:"100vh",sm:'80%'},
    margin:'auto',
    bgcolor: 'primary.light',
    boxShadow: 24,
    color: 'common.white',
    p: 1.8,
    minHeight:800,
    borderRadius: 3,
    marginBottom: 50,
    transition:'1s ease-in-out'
};

const title={
    fontSize:12,
}
const valuesStyle={
    fontSize:14,
    fontWeight:'bold'
}


const MobileLayout=({
    fib0236=0,
    fib0382=0,
    fib0500=0,
    fib0618=0,
    fib0786=0,
    fib1618=0,
    fib2618=0,
    fib3618=0,
    fib4236=0,
    ATH=0,
    ATL=0,
    OG_ATL=0,
    price=0,
    avg_buy=0,
    symbol,
    setModalData,
    png32,
    name='',
    volume=0,
    cap=0,
    market_cap=0,
    circulatingSupply=0,
    maxSupply = 0,
    prices,
    setPrices,
    handleClose,
    setShowCoin,
    setSelectedCoin
})=>{
    const openAddCoin = () => {
        setSelectedCoin(symbol);
        setShowCoin(true);
        handleClose(); 
     }
return (
    <>
    {(!ATH||!ATL) ? 
        <Box sx={{ ...style, display: 'grid', placeContent: 'center' }}>
            <Button onClick={handleClose} sx={{ position: 'absolute', right: -10, top: 0 }}>
                <Close sx={{ color: '#fff', fontWeight: 'bold', fontSize: 35 }} />
            </Button>
            <Typography variant='h3' textAlign={'center'}>No Data!</Typography>
            <Box component={'img'} sx={{height:'110px',mx:'auto',my:3}} src={NoDataImg}/>
            <Typography variant='h5' textAlign={'center'}>Fibonacci Chart can't be created</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2, flexDirection: 'column' }}>
                <Box height={50} component={'img'} src={png32||`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/${symbol?.toLowerCase()}.png`} />
                <Typography variant='h5' sx={{ ml: 1 }}>
                    {symbol?.replaceAll("_",'')||name}
                </Typography>
            </Box>
        </Box> :
    <Box sx={style}>
        <Box sx={{display:'flex',justifyContent:'space-between'}}>        
        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',mb:1,width:'max-content'}}>
        <Badge  badgeContent={<Add onClick={openAddCoin} sx={{color:"#fff",background:"green",borderRadius:"100%",marginBottom:-2,marginLeft:-1,cursor:"pointer"}}/>}>
          <Box height={35} component={'img'} src={png32||`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/${symbol?.toLowerCase()}.png`} />
        </Badge>
          <Box>
            <Typography  sx={{ml:1,fontSize:18,fontWeight:'bold'}}>{symbol?.replaceAll("_",'')||name}</Typography>
            <Typography sx={{ml:1,mt:-1,fontSize:12}}>{name}</Typography>
          </Box>                   
        </Box>

        <Typography sx={{fontWeight:'bold',ml:-5}} variant='h4'>
         {(prices[0]/price).toFixed(1)}
         <span style={{color: 'red',}}>X</span>
        </Typography>

        <Button onClick={handleClose} sx={{mt:-1,mr:-2}}>
            <Close sx={{color:'#fff',fontWeight:'bold',fontSize:35}}/>
        </Button>
        </Box>
        <Grid container textAlign={'center'}  columns={8} my={1}>
            <Grid xs={2}>
                <Typography sx={title}>24hrVol</Typography>
                <Typography sx={valuesStyle}>{FormatToIntCurrency(volume)}</Typography>
            </Grid>
            <Grid xs={2}>
                <Typography sx={title}>MrktCap</Typography>
                <Typography sx={valuesStyle}>{FormatToIntCurrency(cap||market_cap)}</Typography>
            </Grid>
            <Grid xs={2}>
                <Typography sx={title}>CircSply</Typography>
                <Typography sx={valuesStyle}>{FormatToIntQty(circulatingSupply)}</Typography>
            </Grid>
            <Grid xs={2}>
                <Typography sx={title}>MaxSply</Typography>
                <Typography sx={valuesStyle}>{FormatToIntQty(maxSupply)}</Typography>
            </Grid>
        </Grid>
        <Box sx={{ml:0.5,height:{xs:'calc(100vh - 135px)',sm:"86%"}}} className="custom-chart-area" >
            <FibChart
                widths={[800]}
                ratio={0.22}
                values={[
                    fib4236,
                    fib3618,
                    fib2618,
                    fib1618,
                    ATH,
                    fib0786,
                    fib0618,
                    fib0500,
                    fib0382,
                    fib0236,
                    (OG_ATL||ATL) * 1,
                ]}

                price={price}
                avg_buy={(avg_buy)}
                labels={['Fib(4.236)', 'Fib(3.618)', 'Fib(2.618)', 'Fib(1.618)', 'ATH', 'Fib(0.786)', 'Golden Ratio', 'Fib(0.5)', 'Fib(0.382)', 'Fib(0.236)', 'SwingLow']}
            />
            <div style={{ height: '100%', zIndex: 2 }} >
                <FibSlider
                    key={Math.random()}
                    domain={[ATL, fib4236]}
                    values={prices}
                    setPrice={setPrices}
                    
                />
            </div>
        </Box>
    </Box>}
    </>
)
}

export default MobileLayout;