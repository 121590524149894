import { Box, Button, CircularProgress, Typography, TableCell, TableSortLabel} from "@mui/material";
import axios from "axios";
import { CoinTable } from "component/Table";
import FormatToIntCurrency from "utils/FormatToIntCurrency";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import FormateCurrentcy from "utils/FormateCurrency";
import FormateDelta from "utils/FormateDelta";
import Exchanges from "component/Exchanges";
import BottomTab from "component/BottomTab";
import {  Close } from "@mui/icons-material";
import AddCrypto from "component/Modal/AddNewCrypto";
import CustomInputDark from "component/Input/CustomInputDarkText";
import Delta from 'assets/delta.png'
import addEvent from "utils/addEvent";
import FormateDeltaATH from "utils/FormateDeltaATH";
import FibModal from "component/Modal/FibModal";
import { Link } from "react-router-dom";
const SortLabel=({orderBy,order,keyName,Label,handleSort})=>{
  return( 
    <TableCell  onClick={() => handleSort(keyName)} sx={{ bgcolor: "primary.light", color: "common.white", py: 1 }} align="center">
  <TableSortLabel
    active={order!=='normal' && orderBy === keyName}
    direction={orderBy === keyName ? order : 'asc'}
    style={{color:'#fff'}}
    sx={{
      ".MuiTableSortLabel-icon": {
        color: 'white !important',
      },
    }}
  >
    {Label}
  </TableSortLabel>
  </TableCell>
  )
}


export const AllCoin = () => {
  const [apiData, setAPIData] = useState([]);
  const [ogData, setOgData] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showCoin, setShowCoin] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [modalData,setModalData]=useState(null);
  const [query, setQuery] = useState(null)
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('volume');
  const [currentExchanges,setCurrentExchanges]=useState("");
  const [total,setTotal]=useState(null);
  const [showVolume,setShowVolume]=useState(false)
  const [max,setMax]=useState(250000000);
  const [min,setMin]=useState(50000000);
  let drops=['hour','day','week','month','year']
 

  useEffect(()=>{
    addEvent("View AllCoin")
  },[])
  

  useEffect(() => {
    debounce(handleChange(query))
  }, [query])

  useEffect(() => {
    let timer = setInterval(() => {
      fetchItems()
    }, [20 * 1000])
    return () => clearInterval(timer)
  }, [fetchItems])

  const handleSort =useCallback((property)=>{
    console.log('sorting started')
    let key='normal'
    if(order=='normal'){
      key='asc'
    }else if(order=='asc'){
      key='desc'
    }else{
      key='normal'
    }
    if(key=='normal'){
     setOrder(key);
     setOrderBy(property);
     return setAPIData([...ogData]);
    }
    if( drops.includes(property)){
     apiData.sort((a, b) => {
       if (key === 'asc') {
         return (a['delta'][property]||null) < (b['delta'][property]||null) ? -1 : 1;
       } else if(key=='desc'){
         return (a['delta'][property]||null) > (b['delta'][property]||null) ? -1 : 1;
       }
     })
    }
    else if(property=='delta_ATH'){
     apiData.sort((a, b) => {
     if (key === 'asc') {
       return (a['allTimeHighUSD'] / a['rate']) < (b['allTimeHighUSD'] / b['rate']) ? -1 : 1;
     } else if(key=='desc'){
       return(a['allTimeHighUSD'] / a['rate']) > (b['allTimeHighUSD'] / b['rate']) ? -1 : 1;
     }
   })
    }
    else{
     if(key=='normal'){
       setOrder(key);
       setOrderBy(property);
       return setAPIData(ogData);
     }
     apiData.sort((a, b) => {
       if (key === 'asc') {
         return (a[property]||null) < (b[property]||null)? -1 : 1;
       } else if(key=='desc'){
         return (a[property]||null) > (b[property]||null) ? -1 : 1;
       }
     })
    }
    setAPIData([...apiData])
    setOrder(key);
    setOrderBy(property);
    console.log({key,property,msg:"sorting done"})
 },[order,orderBy,apiData,ogData,setAPIData,setOrder,setOrderBy])

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (value) => {
    if (value && value.length > 1) {
      axios.get("/api/v1/crypto/crypto-listing?name=" + value)
        .then(res => {
        if (query == '' || !query) return;
          setAPIData(res.data.result);
          setIsLoading(false);
          setPage(0)
        })
    }
    if (query == '') {
      setAPIData(ogData);
    }
  }

  const heading = useMemo(() => {
    return [
      //  { name: <TableCell  sx={{ bgcolor: "primary.light", color: "common.white", py: 1 }} align="center"></TableCell> },
      { name: <SortLabel order={order} orderBy={orderBy} keyName={'rank'} handleSort={handleSort} Label={'Rank'} /> },
      { name:<TableCell  sx={{ bgcolor: "primary.light", color: "common.white", py: 1 }} align="center"><CustomInputDark type={'text'} endAdornment={query && <Button onClick={() => setQuery('')} sx={{ margin: 0, padding: 0, minWidth: '5px' }}><Close sx={{ color: "#fff", fontSize: 18 }} /></Button>} value={query} onChange={(e) => setQuery(e.target.value)} label='Search ' sx={{ width: 90 }} /></TableCell>  },
   
       { name:<SortLabel order={order} orderBy={orderBy} keyName={'price'} handleSort={handleSort} Label={'Price'} /> },
      // { name: <SortLabel order={order} orderBy={orderBy} keyName={'allTimeHighUSD'} handleSort={handleSort} Label={'ATH'} /> },
      // { name : <SortLabel order={order} orderBy={orderBy} keyName={'ATL'} handleSort={handleSort} Label={'ATL'} />},

      { name:<SortLabel order={order} orderBy={orderBy} keyName={'delta_ATH'} handleSort={handleSort} Label={<><p>2021ATH<span className='x'>X</span></p></>} />},
      // { name: <SortLabel order={order} orderBy={orderBy} keyName={'fibMax'} handleSort={handleSort} Label={'Fb(4.286)'} />},

      { name:  <SortLabel order={order} orderBy={orderBy} keyName={'hour'} handleSort={handleSort} Label={<><img src={Delta} />Hr</>} />},
      { name: <SortLabel order={order} orderBy={orderBy} keyName={'day'} handleSort={handleSort} Label={<><img src={Delta} />Dy</>} />},
      { name: <SortLabel order={order} orderBy={orderBy} keyName={'week'} handleSort={handleSort} Label={<><img src={Delta} />Wk</>} /> },
      { name: <SortLabel order={order} orderBy={orderBy} keyName={'month'} handleSort={handleSort} Label={<><img src={Delta} />Mo</>} />},
      { name: <SortLabel order={order} orderBy={orderBy} keyName={'year'} handleSort={handleSort} Label={<><img src={Delta} />Yr</>} /> },
      { name: <SortLabel order={order} orderBy={orderBy} keyName={'drop'} handleSort={handleSort} Label={<><img src={Delta} />2021ATH</>} />  },
      { name: <SortLabel order={order} orderBy={orderBy} keyName={'volume'} handleSort={handleSort} Label={'24hVol'} /> },
      { name: <SortLabel order={order} orderBy={orderBy} keyName={'cap'} handleSort={handleSort} Label={'MrkCap'} />  },
      { name: <TableCell  sx={{ bgcolor: "primary.light", color: "common.white", py: 1 }} align="center">Exchanges</TableCell>},
    ]
  }, [query,handleSort])

  function fetchItems(exchanges='') {
    if (query && query !== '') return
    setIsLoading(true);
    //axios.get("/api/v1/crypto/crypto-listing?offset=" + (page * 100)+"&exchanges="+currentExchanges+"&volume24="+showVolume )
    axios.get("/api/v1/crypto/crypto-listing?offset=" + (page * 100)+"&volume24="+showVolume )
      .then(res => {
        setTotal(res.data?.total)
        if(order!=='normal' &&  (order&&orderBy)){
          let temp=res.data?.result||[];
          temp.sort((a, b) => {
            if (order === 'asc') {
              return a[orderBy] < b[orderBy] ? -1 : 1;
            } else if(order=='desc'){
              return a[orderBy] > b[orderBy] ? -1 : 1;
            }})
          
            setAPIData(temp);
        }else{
          setAPIData(res.data?.result||[]);
        }
        setOgData(res.data?.result||[])
        setIsLoading(false);
      })
  };

  useEffect(() => {
    fetchItems();
  }, [page,showVolume]);

  const SetRowIndex=(index)=>{
    setModalData(apiData[index])
  }

  const tableRows = useMemo(() => {
    return apiData.map((obj,index) => {
      if (!obj.rate) {
        return undefined
      }
      if(max&&min){
        if(!(obj.cap<=max) || !(min<=obj.cap))return undefined
      }else if(max){
        if(!(obj.cap<=max))return undefined
      }else if(min){
        if(!(min<=obj.cap))return undefined
      }
      if(currentExchanges && !obj.exchange.includes(currentExchanges)){
         return;
      }
      return [
        // { element: <Button onClick={() => { setSelectedCoin(obj.symbol || obj.code); setShowCoin(true) }} sx={{ minWidth: 5, padding: '6px 0px', m: 0 }}> <img src={AddCircle} /></Button>, sx: { paddinng: '6px 2px', m: 0 } },
        { element: obj.rank, sx: { px: 0, } ,symbol:obj.symbol||obj.code},
        {
          element: <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', px: 0,mx:0, width: 'max-content', mx: 'auto' }}>
            <Box>
              <Button sx={{minWidth:50}} onClick={() => { SetRowIndex(index) }}> <img style={{ height: 28 }} src={obj.png32} /></Button>
            </Box>
            <Box sx={{ textAlign:'left' }}>
              <Link style={{ textDecoration: 'none',color: '#fff', fontWeight: '500',display:'block', fontSize: 14, marginTop: 1, textOverflow:'ellipsis',whiteSpace:"nowrap",width:60,overflow:'hidden' }} onClick={() => { SetRowIndex(index) }}>
                {obj?.symbol?.replaceAll('_','') || obj?.code?.replaceAll('_','')}
              </Link>
              <Typography sx={{fontSize: 10, textOverflow:'ellipsis',whiteSpace:"nowrap",width:60,overflow:'hidden' }}>{obj.name}</Typography>
            </Box>
          </Box>,
          sx: { px: 0, bgcolor:"#293143",cursor:"pointer" }
        },
        { element: FormateCurrentcy(obj.rate || 0) },
        // { element: FormateCurrentcy(obj.allTimeHighUSD || 0) },
        // { element: <span style={{background:obj.price<obj.ATL?"yellow":"none"}}>{!obj.ATL ?'NA':FormateCurrentcy(obj.ATL )}</span>},
        { element:  <span>{(obj.allTimeHighUSD / obj.rate).toFixed(1)} <span className="x">X</span></span> },
        // { element: !obj.fibMax ?'NA':FormateCurrentcy(obj.fibMax ) },
        
        { ...FormateDelta(obj.delta?.hour || 0) },
        { ...FormateDelta(obj.delta?.day || 0) },
        { ...FormateDelta(obj.delta?.week || 0) },
        { ...FormateDelta(obj.delta?.month || 0) },
        { ...FormateDelta(obj.delta?.year || 0) },
        {...FormateDeltaATH (obj.drop) },
        { element: FormatToIntCurrency(obj.volume) },
        { element: obj.cap?FormatToIntCurrency(obj.cap):"NA" },
        { element: <Exchanges symbol={obj.symbol||obj.code}  data={obj.exchange} />, sx: {} }


      ]
    }).filter(obj => obj !== undefined);
  }, [apiData,SetRowIndex,max,min])




  const setFilter=(e)=>{
    if(!e.target.checked){
      setMax(null)
      setMin(null)
      return;
    }
    if(e.target.name=='LgCap'){
      setMax(null)
      setMin(1000000000)
    }else if(e.target.name=='MidCap'){
      setMax(1000000000)
      setMin(250000000)
    }else if(e.target.name=='LowCap'){
      setMax(250000000)
      setMin(50000000)
    }else if(e.target.name=='McrCap'){
      setMax(50000000)
      setMin(null);
    }
  }
 
  return (
    <>
      <AddCrypto setQuery={setQuery} visible={showCoin} selectedCoin={selectedCoin} setSelectedCoin={setSelectedCoin} setVisble={setShowCoin} />
     {modalData && <FibModal setModalData={setModalData} setShowCoin={setShowCoin} setSelectedCoin={setSelectedCoin} {...modalData} avg_buy={undefined}/>} 
      <Box sx={{ paddingTop: '10px', bgcolor: 'primary.main' }}>
        {isLoading && <CircularProgress size={50} sx={{ mx: 'auto', color: "#fff", display: 'block', position: 'absolute', left: '50%', }} />}
        <CoinTable apiData={apiData}  SetRowIndex={SetRowIndex} total={total} setFilter={setFilter} currentExchanges={currentExchanges} min={min} max={max} setMin={setMin} setMax={setMax} setCurrentExchanges={setCurrentExchanges} rows={tableRows} page={page} setPage={setPage} headers={heading} />
        <BottomTab />
      </Box>
    </>)
}


export default AllCoin;