import React, { useMemo } from 'react'
import { Box, styled } from '@mui/material'



const ChartContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingBottom: '50%',
  letterSpacing: '0px',
}))

const SVGContainer = styled('svg')(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
}))

export function GaugeChart({
  symbol,
  currentXGain,
  // targetXGain,
  maxXGain,
  exit1,
  exit2,
  exit3,
  exit4,
  showAlertIcon,
  showExitTicksAndBtc
}) {
  const getCoordinatesForPercent = (percent) => {
    const angle = ((percent / maxXGain) * 180 + 180) * (Math.PI / 180)
    const x = Math.cos(angle)
    const y = Math.sin(angle)
    return [x, y]
  }

  const createXGainNeedle = (percent, color) => {
    const [x1, y1] = getCoordinatesForPercent(percent)
    const [x2, y2] = [x1 * 0.8, y1 * 0.8]  // Base of the triangle
    const [labelX, labelY] = [x1 * 0.7, y1 * 0.7]  // Label position
    const angle = ((percent / maxXGain) * 180 + 180) * (Math.PI / 180)
    const x3 = Math.cos(angle + Math.PI / 6) * 0.03 + x2
    const y3 = Math.sin(angle + Math.PI / 6) * 0.03 + y2
    const x4 = Math.cos(angle - Math.PI / 6) * 0.03 + x2
    const y4 = Math.sin(angle - Math.PI / 6) * 0.03 + y2

  

    return (
      <g key={percent}>
        <path
          d={`M ${x1} ${y1} L ${x3} ${y3} L ${x4} ${y4} Z`}
          fill="none"
          stroke={color}
          strokeWidth="0.02"
        />
        <text
          x={labelX}
          y={labelY}
          fontSize="0.06"
          fill={color}
          fontFamily="Arial Black, Arial, sans-serif"
          fontWeight="bold"
          textAnchor="middle"
          dominantBaseline="central"
        >
          {percent.toFixed(1)}<tspan fill="red" fontSize="0.07">X</tspan>
        </text>
      </g>
    )
  }

  const createExitNeedle = (percent) => {
    const [x, y] = getCoordinatesForPercent(percent)
    return (
      <circle
        key={percent}
        cx={x}
        cy={y}
        r="0.015"
        fill="white"
      />
    )
  }

  const exitPointMeets=useMemo(()=>{
    let arr=[exit1,exit2,exit3,exit4];
    let meets=false;
    let onePercent=maxXGain/100;
    for(let i=0;i<arr.length;i++){
      if(arr[i]>(currentXGain-onePercent) && arr[i]<(currentXGain+onePercent)){
        meets=true;
        break;
      }
    }
    return meets;
  },[exit1,exit2,exit3,exit4])
  return (
    <ChartContainer>
      <SVGContainer
        viewBox="-1.1 -1.1 2.2 1.3"
        preserveAspectRatio="xMidYMid meet"
      >
        <defs>
          <linearGradient id="gaugeGradient" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#ef4444" />
            <stop offset="50%" stopColor="#f97316" />
            <stop offset="100%" stopColor="#22c55e" />
          </linearGradient>
          <filter id="glow">
            <feGaussianBlur stdDeviation="0.02625" result="coloredBlur"/>
            <feGaussianBlur stdDeviation="0.042" result="coloredBlur2"/>
            <feGaussianBlur stdDeviation="0.05775" result="coloredBlur3"/>
            <feMerge>
              <feMergeNode in="coloredBlur"/>
              <feMergeNode in="coloredBlur2"/>
              <feMergeNode in="coloredBlur3"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
          <radialGradient id="haloGradient">
            <stop offset="0%" stopColor="white" stopOpacity="0.21" />
            <stop offset="40%" stopColor="white" stopOpacity="0.105" />
            <stop offset="70%" stopColor="white" stopOpacity="0.0525" />
            <stop offset="100%" stopColor="white" stopOpacity="0" />
          </radialGradient>
          <style>
            {`
              @keyframes pulse {
                0% { opacity: 1; }
                50% { opacity: 0.5; }
                100% { opacity: 1; }
              }
              .pulse {
                animation: pulse 1.5s infinite;
              }
              @keyframes rotate {
                from { transform: rotate(0deg); }
                to { transform: rotate(360deg); }
              }
              .rotating-dot {
                animation: rotate 10s linear infinite;
              }
            `}
          </style>
        </defs>
        <path
          d="M -1 0 A 1 1 0 0 1 1 0"
          fill="none"
          stroke="url(#gaugeGradient)"
          strokeWidth="0.1"
        />
        {[0,(maxXGain * 0.2), (maxXGain * 0.4), (maxXGain * 0.6), (maxXGain * 0.8), maxXGain].map((tick) => {
          const [x, y] = getCoordinatesForPercent(tick)
          return (
            <g key={tick}>
              <line
                x1={x * 0.95}
                y1={y * 0.95}
                x2={x}
                y2={y}
                stroke="white"
                strokeWidth="0.01"
              />
              <text
                x={x * 0.85}
                y={y * 0.85}
                fontSize="0.06"
                fill="white"
                fontFamily="Arial Black, Arial, sans-serif"
                fontWeight="bold"
                textAnchor="middle"
                dominantBaseline="central"
              >
               {isNaN(tick)==true?"" : <>{tick.toFixed(1)}<tspan fill="red" fontSize="0.07">X</tspan></>}
              </text>
            </g>
          )
        })}
        {createXGainNeedle(currentXGain, "#EAB308")}
        {/* {createXGainNeedle(targetXGain, "#22C55E")} */}
        {showExitTicksAndBtc && (
          <>
            {createExitNeedle(exit1)}
            {createExitNeedle(exit2)}
            {createExitNeedle(exit3)}
            {createExitNeedle(exit4)}
          </>
        )}
        {/* <circle cx="0" cy="0" r="0.05" fill="#1F2937" /> */}
        {(showAlertIcon &&(exitPointMeets)) && (
          <text
            x="0"
            y="-0.5"
            fontSize="0.3"
            fontFamily="Arial Black, Arial, sans-serif"
            fontWeight="bold"
            fill="#22C55E"
            textAnchor="middle"
            dominantBaseline="central"
            className="pulse"
          >
            $
          </text>
        )}
        {showExitTicksAndBtc && (
          <g filter="url(#glow)">
            <circle cx="0" cy="-0.3" r="0.28" fill="url(#haloGradient)" />
            <image
              href={`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/64/${symbol?.toLowerCase()}.webp`}
              x="-0.2"
              y="-0.5"
              width="0.4"
              height="0.4"
              preserveAspectRatio="xMidYMid meet"
            />
          </g>
        )}
        {showExitTicksAndBtc ? (
          <image
            href="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/xg_logo-aJVGGMUZoq4nmikgo3pBZiF5BKIH30.png"
            x="-0.2"
            y="-0.2"
            width="0.4"
            height="0.4"
            preserveAspectRatio="xMidYMid meet"
          />
        ) : (
          <image
            href="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/xg_logo-aJVGGMUZoq4nmikgo3pBZiF5BKIH30.png"
            x="-0.3"
            y="-0.3"
            width="0.6"
            height="0.6"
            preserveAspectRatio="xMidYMid meet"
          />
        )}
      </SVGContainer>
    </ChartContainer>
  )
}

