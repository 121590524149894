import * as React from 'react';
import Modal from '@mui/material/Modal';
import MobileLayout from './MobileLayout';
import DesktopLayout from './DesktopLayout';
import {useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material';



const FibModal=({
    fib0236=0,
    fib0382=0,
    fib0500=0,
    fib0618=0,
    fib0786=0,
    fib1618=0,
    fib2618=0,
    fib3618=0,
    fib4236=0,
    ATH=0,
    ATL=0,
    OG_ATL=0,
    price=0,
    avg_buy=0,
    symbol,
    setModalData,
    png32,
    name='',
    link,
    volume=0,
    cap=0,
    market_cap=0,
    circulatingSupply=0,
    maxSupply=0,
    exchange=[],
    setShowCoin,
    setSelectedCoin
})=>{
    const [open,setOpen]=React.useState(true)
    const [prices,setPrices]=React.useState([ATH]);
    const props={
    fib0236,
    fib0382,
    fib0500,
    fib0618,
    fib0786,
    fib1618,
    fib2618,
    fib3618,
    fib4236,
    ATH,
    ATL,
    OG_ATL,
    price,
    avg_buy,
    symbol,
    setModalData,
    png32,
    name,
    volume,
    cap,
    market_cap,
    circulatingSupply,
    maxSupply,
    link
    }
    React.useEffect(()=>{
        if(!open)setOpen(true);
    },[symbol,open])
    const theme=useTheme()
    const isMobile=useMediaQuery(theme.breakpoints.down('sm'))
    const handleClose=()=>{setModalData(null)}
    return(
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            // style={{overflowY:'scroll'}}
        >
         {isMobile? <MobileLayout {...props} 
           prices={prices}
           setPrices={setPrices}
           handleClose={handleClose}
           setShowCoin={setShowCoin}
            setSelectedCoin={setSelectedCoin}
           />
           :
           <DesktopLayout
            {...props} 
            prices={prices}
            setPrices={setPrices}
            exchanges={exchange}
            handleClose={handleClose}
            setShowCoin={setShowCoin}
            setSelectedCoin={setSelectedCoin}
           />}
        </Modal>
    )
}

export default FibModal;