import { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography, useMediaQuery, Card, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import FormateCurrentcy from "utils/FormateCurrency";
import FormatToIntCurrency from "utils/FormatToIntCurrency";
import { getCryptoDetail } from "api/crypto";
import CustomeChart from "component/Chart/CustomeChart";
import SellTable from "component/SellTable";
import { useTheme } from "@emotion/react";
import SnackBarFibs from "component/SnackBars/SnackbarFibs";
import GaugeChartAssets from "component/GaugeChart/Assets";
import SettingsIcon from '@mui/icons-material/Settings';
import SellTableModal from "component/Modal/SellTable";
import EditCrypto from "component/Modal/EditCrypto";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ExchangeandWallet from "component/ExchangeWallet";
import addEvent from "utils/addEvent";
import ActivityTable from "component/Table/ActivityTable";
import AddMoreCrypto from "component/Modal/AddMoreCrypto";
import { Badge } from '@mui/material'
import axios from "axios";
import { Add } from "@mui/icons-material";
import FormatToIntQty from "utils/FormatToIntQty";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TradingViewChart from "component/Chart/TradingViewChart";
import NoDataImg from 'assets/chr_NoData.png'
import SellPriceInput from "component/SellPriceInput";
import {GaugeChart} from "component/GaugeChart/new-gauge-chart";
export const AssetsDetail = ({ selected, fetchAll }) => {
    const { symbol } = useParams();
    const [data, setData] = useState(null);
    const [price_data, setPrice] = useState([]);
    const [set, setSets] = useState({});
    const theme = useTheme();
    const [visible, setVisble] = useState(false)
    const [editing, setEditing] = useState(false)
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const isLg = useMediaQuery(theme.breakpoints.up('lg'))
    const [activity, setActivity] = useState([]);
    const [addMoreData, setAddMoreData] = useState(null);
    const [value, setValue] = useState('1');



    useEffect(() => {

        addEvent('View Assets Detail', { symbol: symbol || selected?.symbol || 'no' })
        axios.get(`/api/v1/crypto/activity/${symbol || selected?.symbol}`)
            .then(res => {
                setActivity(res.data)
            })
            .catch()

    }, [symbol, selected?.symbol])

    const fetchData = () => {
        getCryptoDetail('main', symbol || selected.symbol)
            .then(res => { setData({ ...res.data, ATL: (res.data.OG_ATL || res.data.ATL) * 1 }) })
    }
    useEffect(() => {
        fetchData();
    }, [symbol,selected?.price])

    useEffect(() => {
        if (selected) {
            setData({ ...selected, ATL: (selected.OG_ATL || selected.ATL) * 1 })
        }
    }, [selected])
    useEffect(() => {
        if (data) {
            setPrice([data.sell1_price, data.sell2_price, data.sell3_price, data.sell4_price])
            setSets({
                sell1_set: data.sell1_set,
                sell2_set: data.sell2_set,
                sell3_set: data.sell3_set,
                sell4_set: data.sell4_set,
            })
        }
    }, [data])

    const handleChange = (event,newValue) => setValue(newValue);

    if (!data) {
        return (
            <Box sx={{ width: '100vw', height: '80vh' }}><CircularProgress sx={{ color: '#fff' }} /></Box>
        )
    }


    const tempSubmit = () => {
        let btn = document.querySelector('#save-table-save');
        if (btn) {
            btn.click();
        }
    }

    const handleEditingClose = () => {
        setEditing(false);
        fetchData();
    }

    return (
        <Box sx={{ maxWidth: '100vw', overflow: 'hidden', }}>
            <SnackBarFibs data={data} />
            {editing && <EditCrypto setVisble={setEditing} refresh={handleEditingClose} id={data._id} visible={editing} symbol={(selected?.symbol || symbol)} />}
            {addMoreData && <AddMoreCrypto refresh={() => { fetchAll(); fetchData() }} visible={true} setVisble={setAddMoreData} id={data._id} symbol={data.symbol} handleClose={() => setAddMoreData(null)} />}
            <Grid container columns={12}>
                <Grid item xs={12} md={6}>
                    {/* <GaugeChartAssets
                        total_invested={data.invested}
                        total_worth={data.qty * data.price}
                        profit_loss={data.qty * data.price - data.invested}
                        xgain={(data.grand_total_sell / data.invested).toFixed(1)}
                        total_roi={data.grand_total_sell}
                        label={data.name}
                        symbol={data.symbol}
                    /> */}
                    <Card  elevation={0} sx={{bgcolor:'primary.light',boxShadow:'0px 0px 2px #fff',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',padding:2,height:'100%'}}>
                    <GaugeChart
                        key={data.symbol}
                        symbol={data.symbol}
                        currentXGain={parseInt((data.price*data.qty)/data.invested )}
                        maxXGain={parseInt((data.fib4236*data.qty)/data.invested)}
                        exit1={parseInt((price_data[0]*data.qty)/data.invested)}
                        exit2={parseInt((price_data[1]*data.qty)/data.invested)}
                        exit3={parseInt((price_data[2]*data.qty)/data.invested)}
                        exit4={parseInt((price_data[3]*data.qty)/data.invested)}
                        showExitTicksAndBtc={true}
                        showAlertIcon={true}
                        
                    />
                    </Card>
                </Grid>
                <Grid item md={6} flexDirection={'column'} display={{ xs: 'none', md: 'flex' }} alignItems={'stretch'}>
                    <Card elevation={0} sx={{ bgcolor: 'primary.light', boxShadow: '0px 0px 2px #fff', width: '100%', display: 'flex', flexDirection: 'column', padding: 2, height: '100%' }}>
                        <Grid>
                            {!isMobile && <SellTable
                                price={data.price}
                                sell1_price={price_data[0]}
                                sell2_price={price_data[1]}
                                sell3_price={price_data[2]}
                                sell4_price={price_data[3]}
                                invested={data.invested}
                                sell1_set={set.sell1_set}
                                sell2_set={set.sell2_set}
                                sell3_set={set.sell3_set}
                                sell4_set={set.sell4_set}
                                avg_buy={data.avg_buy}
                                qty={data.qty}
                                setSets={setSets}
                                id={data._id}
                                fetchData={fetchData}
                                fetchAll={fetchAll}
                                symbol={selected?.symbol || symbol}
                            />
                            }
                        </Grid>

                        <Grid container columns={8} sx={{ height: '100%' }}>
                            <Grid item md={2} sx={{ display: 'grid', placeContent: 'center', flex: 1, height: '100%' }}>
                                <Typography sx={{ color: "#fff", fontWeight: 'bold', textAlign: 'center', fontSize: { xs: 16, md: 14, lg: 16 } }}>24hrVol </Typography>
                                <Typography sx={{ color: "#fff", textAlign: 'center', fontSize: { xs: 16, md: 14, lg: 16 } }}>{FormatToIntCurrency(data.volume)}</Typography>
                            </Grid>
                            <Grid item md={2} sx={{ display: 'grid', placeContent: 'center', flex: 1, height: '100%' }}>
                                <Typography sx={{ color: "#fff", fontWeight: 'bold', textAlign: 'center', fontSize: { xs: 16, md: 14, lg: 16 } }}>MrktCap </Typography>
                                <Typography sx={{ color: "#fff", textAlign: 'center', fontSize: { xs: 16, md: 14, lg: 16 } }}>{data.market_cap ? FormatToIntCurrency(data.market_cap) : "NA"}</Typography>
                            </Grid>
                            <Grid item md={2} sx={{ display: 'grid', placeContent: 'center', flex: 1, height: '100%' }}>
                                <Typography sx={{ color: "#fff", fontWeight: 'bold', textAlign: 'center', fontSize: { xs: 16, md: 14, lg: 16 } }}>CircSply </Typography>
                                <Typography sx={{ color: "#fff", textAlign: 'center', fontSize: { xs: 16, md: 14, lg: 16 } }}>{FormatToIntQty(data.circulatingSupply)}</Typography>
                            </Grid>
                            <Grid item md={2} sx={{ display: 'grid', placeContent: 'center', flex: 1, height: '100%' }}>
                                <Typography sx={{ color: "#fff", fontWeight: 'bold', textAlign: 'center', fontSize: { xs: 16, md: 14, lg: 16 } }}>MaxSply</Typography>
                                <Typography sx={{ color: "#fff", textAlign: 'center', fontSize: { xs: 16, md: 14, lg: 16 } }}>{FormatToIntQty(data.maxSupply)}</Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            {isMobile && <SellTableModal
                visible={visible}
                price={data.price}
                setVisible={setVisble}
                sell1_price={price_data[0]}
                sell2_price={price_data[1]}
                sell3_price={price_data[2]}
                sell4_price={price_data[3]}
                invested={data.invested}
                sell1_set={set.sell1_set}
                sell2_set={set.sell2_set}
                sell3_set={set.sell3_set}
                sell4_set={set.sell4_set}
                avg_buy={data.avg_buy}
                qty={data.qty}
                setSets={setSets}
                id={data._id}
                fetchData={fetchData}
                fetchAll={fetchAll}
                symbol={selected?.symbol || symbol}
                name={data.name}
            />}
            <Box sx={{marginBottom:10}}>
                <Box>
                    <Box sx={{ marginTop: 1, width: '100%', position: "relative", }}>
                        <Grid container columns={4}>
                            <Grid sx={{ width: '100%' ,height:'100%'}} xs={4} md={2}>
                                <Box sx={{ p: 1, my: 1, display: {md:'flex'}, justifyContent: { xs: 'space-between', md: "left" } }}>
                                    
                                    <Typography variant="h5" sx={{ display:'flex',whiteSpace:"nowrap",alignItems:"center", color: '#fff', fontWeight: 'bold', textAlign: 'left' }}>
                                        Fibonacci {data?.symbol?.replaceAll('_', '')}
                                        <Button sx={{ minWidth: 0,width:30,height:30,fontSize: 20, mx: 0.7,background:'green',borderRadius:'100%' }} onClick={() => setEditing(true)}>
                                           <ModeEditIcon sx={{ color: '#fff', fontSize: 12 }} />
                                        </Button>
                                        <Button sx={{ minWidth: 0,width:30,height:30,fontSize: 20, mx: 0.2, mr: 1, background:'green',borderRadius:'100%'}} onClick={() => {setAddMoreData(data)}} >
                                           <Add sx={{ color: '#fff', fontSize: 14 }} />
                                        </Button>
                                    </Typography>
                                    
                                    
                                    <Box sx={{display:{sx:'block',md:'inline-block'}}}>
                                    <ExchangeandWallet id={data._id} />
                                    </Box>
                                    <Button sx={{ minWidth: 0,position:'absolute',top:0,right:5, fontSize: 20, display: { md: "none" } }} onClick={() => { setVisble(true) }}>
                                        <SettingsIcon sx={{ color: "#fff" }} />
                                    </Button>
                                </Box>
                                <Box sx={{ marginLeft: { xs: 2, md: 0, px: 2 } }}>
                                    {((data.ATH && data.ATL) || (data.OG_ATH && data.OG_ATL)) &&
                                        data.ATH > data.ATL ?
                                        <CustomeChart
                                            key={selected?.symbol || symbol}
                                            price_data={price_data}
                                            setPrice={setPrice}
                                            {...data} /> : 
                                        <Box sx={{ display: 'grid', placeContent: 'center',mt:10,color:"#fff"}}>
                                            <Typography variant='h4' textAlign={'center'}>No 2021 Data!</Typography>
                                            <Box component={'img'} sx={{ height: '110px', mx: 'auto', my: 3 }} src={NoDataImg} />
                                            <Typography textAlign={'center'}>Fibonacci Chart can't be created as 2021 All Time High & All Time Low values are not available for {(symbol||data.symbol)?.replaceAll("_", '')}</Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2, flexDirection: 'column' }}>
                                                
                                                <Typography variant='h6' sx={{ ml: 1,mt:2 }}>SELL TO EXIT VALUES</Typography>
                                                <Typography> Enter your Exit Values based on your price predictions manually</Typography>
                                            </Box>
                                            <SellPriceInput price={price_data} setPrice={setPrice}/>
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                            {isLg && <Grid xs={4} md={2} sx={{height:"65vh",flex:1}}> 
                                <TabContext value={value} >
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange}   TabIndicatorProps={{ style: { backgroundColor: '#fff' } }} aria-label="lab API tabs example">
                                            <Tab label="Chart"  style={{  color: 'white' }} value="1" />
                                            <Tab label="Activity"  style={{ color: 'white' }} value="2" />
                                            {/* <Tab label="Exit Settings"  style={{ color: 'white' }} value="3" /> */}
                                        </TabList>
                                    </Box>
                                    <TabPanel style={{padding:0}} sx={{height:'60vh'}}  value="1">
                                       {data.symbol && <TradingViewChart symbol={data.symbol?.replaceAll('_','')} />}
                                    </TabPanel>
                                    <TabPanel sx={{height:'100%'}} value="2">
                                      <ActivityTable data={activity} />
                                    </TabPanel>
                                </TabContext>
                            </Grid>
                            }
                        </Grid>
                        {((data.ATH && data.ATL) || (data.OG_ATH && data.OG_ATL)) &&
                                        data.ATH > data.ATL ?
                        <Box sx={{ position: 'absolute', bottom: 100, right: 5 }}>
                            <Button sx={{
                                cursor: 'pointer',
                                display: { md: 'none', xs: 'block' },
                                marginLeft: 'auto',
                                marginBottom: 0.5,
                                padding: '5px 15px',
                                border: '1px solid grey', borderRadius: 10
                            }}

                                variant="contained"
                                onClick={tempSubmit}>Save</Button>
                        </Box>:null}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AssetsDetail;