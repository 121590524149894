import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import img_tab from 'assets/img_tab.png'
import VIDEO from 'assets/whatsnew.mp4'
import { useSelector } from 'react-redux';
import FormateCurrency from 'utils/FormateCurrency';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 610,
    width:'90%',
    margin:'auto',
    bgcolor: 'primary.light',
    boxShadow: 24,
    color: 'common.white',
    p: 1.8,
    borderRadius: 3,
    marginBottom: 50,
};

export default function Greeting({belowAvg,p_and_l,totalCoin,show,setShow}) {
    const [open,setOpen]=React.useState(true)
    const {user}=useSelector(state=>state.user)
    const handleClose=()=>{
        if(setShow){
            setShow(false);
        }
        setOpen(false)
    }

    const text=React.useMemo(()=>{
        let hr=new Date().getHours();
        if(hr<12){
            return "Good Morning"
        }else if(hr<18){
            return "Good Afternoon"
        }else{
            return "Good Evening "
        }
    },[])
    return (
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box component='form'  sx={style}>
                <Box sx={{ marginBottom: 1, marginTop: 2, textAlign: 'center' }}>
                      <Box sx={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                        <Typography sx={{color:'#fff',}} variant='h5'>{text}</Typography>
                        <Typography sx={{color:'#fff',}} variant='h5'>{user?.firstname || user?.name?.split(" ")[0]}</Typography><br/>
                     </Box>
                </Box>
             
                <Box sx={{px:2,mt:1}}>
                <Typography sx={{color:"#fff",textAlign:"center", fontSize:28, fontWeight:"bold"}}> You Have</Typography>
                 <Typography sx={{color:"#fff",textAlign:"center", fontSize:20 }}>{totalCoin}/{belowAvg} Coins below your average cost</Typography>
                 <Typography sx={{color:"#fff",textAlign:"center",fontSize:20}}>{ FormateCurrency((p_and_l>0?p_and_l:0))  }  Profit Trim Opportunity</Typography>
                </Box>
                <Box>
                    <Box sx={{width:'100%',my:2}} component={'video'} src={VIDEO} playsInline loop autoPlay muted />
                </Box>
                <Box sx={{ width: '100%', height: 1.1, background: '#fff', marginTop: 2 }} />
                <Box sx={{ paddingTop: 3,display:'block',textAlign:'center' }}>
                    <Button  onClick={handleClose} sx={{ color: '#fff', px:4,borderColor: "#fff", borderRadius: '20px' }} variant="outlined">OK</Button>
                </Box>
            </Box>
        </Modal>
    );
}


// sx={{border:"1px solid #fff"}} labelStyle={{background:'black',padding:'0px 5px'}} style={{color:'#fff'}} 