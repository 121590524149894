import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FibChart from "component/Chart/FibChart";
import FibSlider from 'component/Slider/FibSlider';
import { Grid, Typography,Badge } from '@mui/material';
import { Cancel, Close } from '@mui/icons-material';
import FormatToIntQty from 'utils/FormatToIntQty';
import FormatToIntCurrency from 'utils/FormatToIntCurrency';
import TradingViewChart from 'component/Chart/TradingViewChart';
import FormateCurrency from 'utils/FormateCurrency';
import Exchanges from 'component/Exchanges';
import NoDataImg from 'assets/chr_NoData.png'
import { Add } from '@mui/icons-material';
const style = {
    position: 'absolute',
    display: 'block',
    top: { xs: '0%', sm: '50%' },
    left: { xs: '0', sm: '50%' },
    transform: { xs: '', sm: 'translate(-50%, -50%)' },
    maxWidth: { xs:'1200px', },
    width: { xs: "100vw", sm: '98%',md:"95%" },
    height: { xs: "100vh", sm: '80%' },
    margin: 'auto',
    bgcolor: 'primary.light',
    boxShadow: 24,
    color: 'common.white',
    p: 1.8,
    minHeight: 800,
    borderRadius: 3,
    marginBottom: 50,
    transition: '1s ease-in-out'
};

const title = {
    fontSize: 18,
    textAlign: 'center'
}
const valuesStyle = {
  
    textAlign: 'center'
}

const DesktopLayout = (
    {
        fib0236 = 0,
        fib0382 = 0,
        fib0500 = 0,
        fib0618 = 0,
        fib0786 = 0,
        fib1618 = 0,
        fib2618 = 0,
        fib3618 = 0,
        fib4236 = 0,
        ATH = 0,
        ATL = 0,
        OG_ATL = 0,
        price = 0,
        avg_buy = 0,
        symbol,
        setModalData,
        png32,
        name = '',
        volume = 0,
        cap = 0,
        market_cap = 0,
        circulatingSupply = 0,
        maxSupply = 0,
        prices,
        setPrices,
        handleClose,
        exchanges,
        link,
        setShowCoin,
        setSelectedCoin
    }) => {

    const openAddCoin = () => {
       setSelectedCoin(symbol);
       setShowCoin(true);
       handleClose(); 
    }
    return (
        <Grid columns={12} container sx={{ ...style}} >
             <Button onClick={handleClose} sx={{top:-20,right:-20,minWidth:0,m:0,p:0,bgcolor:"none",position:'absolute',zIndex:999}}>
                <Cancel style={{color:"#fff",fontSize:40}} color='#fff' />
            </Button>
            <Box> 
                <Box px={2} my={2} sx={{display:'flex'}} >
                    <Box ml={2} sx={{display:'flex',alignItems:'center'}}>
                        <Badge  badgeContent={<Add onClick={openAddCoin} sx={{color:"#fff",background:"green",borderRadius:"100%",marginBottom:-2,marginLeft:-1,cursor:"pointer"}}/>}>
                        <Box component={'img'} onClick={openAddCoin} src={png32||`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/64/${symbol?.toLowerCase()}.png`}  height={50} />
                        </Badge>
                        <Box sx={{ml:2}}>
                            <Typography sx={{fontSize:18}}>{name}</Typography>
                            <Typography>{symbol.replaceAll('_','')}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ml:6,textAlign:'center'}}>
                      <Typography sx={{fontSize:18}}>{FormateCurrency(price)}</Typography>
                      <Typography component={'a'} target={'_blank'} href={link} sx={{color:"#fff",borderBottom:'1px solid grey',display:'inline-block'}}>{symbol.replaceAll('_','')} Website</Typography> 
                    </Box>
                    <Box>
                    <Grid columns={12} container px={3} gap={5} >
                    <Grid xs={2}>
                        <Typography sx={title}>MrktCap</Typography>
                        <Typography sx={valuesStyle}>{FormatToIntCurrency(cap || market_cap)}</Typography>
                    </Grid>
                    <Grid xs={2}>
                        <Typography sx={title}>24hrVol</Typography>
                        <Typography sx={valuesStyle}>{FormatToIntCurrency(volume)}</Typography>
                    </Grid>
                    <Grid xs={2}>
                        <Typography sx={title}>CircSply</Typography>
                        <Typography sx={valuesStyle}>{FormatToIntQty(circulatingSupply)}</Typography>
                    </Grid>
                    <Grid xs={2}>
                        <Typography sx={title}>MaxSup</Typography>
                        <Typography sx={valuesStyle}>{FormatToIntQty(maxSupply)}</Typography>
                    </Grid>
                </Grid>
                    </Box>
                    <Box sx={{ml:1,display:{xs:"none",xl:'flex'}}}>
                        <Exchanges data={exchanges} size={28}/>
                    </Box>
                 </Box>
                 <Box sx={{mx:'auto',width:"max-content",display:{xs:"flex",xl:'none'}}}>
                        <Exchanges data={exchanges} size={28}/>
                    </Box>
            </Box>
            <Box sx={{display: 'flex',height:{md:'calc(100% - 120px)',xs:'calc(100% - 140px)'}}}>
            <Grid xs={3.5} sx={{  height: '100%',display:'flex',position:'relative',mt:2 }}>
           {ATL!=0?
            <>
            <FibChart
                widths={[800]}
                ratio={1}
                values={[
                    fib4236,
                    fib3618,
                    fib2618,
                    fib1618,
                    ATH,
                    fib0786,
                    fib0618,
                    fib0500,
                    fib0382,
                    fib0236,
                    (OG_ATL||ATL) * 1,
                ]}

                price={price}
                avg_buy={(avg_buy)}
                labels={['Fib(4.236)', 'Fib(3.618)', 'Fib(2.618)', 'Fib(1.618)', 'ATH', 'Fib(0.786)', 'Golden Ratio', 'Fib(0.5)', 'Fib(0.382)', 'Fib(0.236)', 'SwingLow']}
            />
            <div style={{ height: '100%', zIndex: 2,position:'absolute',right:-20}} >
                <FibSlider
                    key={Math.random()}
                    domain={[ATL, fib4236]}
                    values={prices}
                    hideSliderNumber={true}
                    setPrice={setPrices}
                    style={{marginLeft:-10}}
                    dotWidth={30}
                    dotHeight={30}
                    
                />
            </div>
            </>
            :
            <Box sx={{ display: 'grid', placeContent: 'center',w:'100%' }}>
            <Typography variant='h3' textAlign={'center'}>No Data!</Typography>
            <Box component={'img'} sx={{height:'110px',mx:'auto',my:3}} src={NoDataImg}/>
            <Typography variant='h6' textAlign={'center'}>Fibonacci Chart can't be created</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2, flexDirection: 'column' }}>
                <Box height={50} component={'img'} src={png32||`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/${symbol?.toLowerCase()}.png`} />
                <Typography variant='h5' sx={{ ml: 1 }}>
                    {symbol?.replaceAll("_",'')||name}
                </Typography>
            </Box>
           </Box>}

            </Grid>
            <Grid xs={8.5} sx={{ height: '100%',display:'flex',flexDirection:'column',pl:1 }}>
                  
                <Box sx={{flex:1}}>
                <TradingViewChart symbol={symbol?.replaceAll('_','')||'btc'} />
                </Box>
            </Grid>
            </Box >
        </Grid >
    )
}

export default DesktopLayout;