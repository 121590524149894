import { useState,useEffect } from "react";
import { Box, Button } from "@mui/material";
import HeroSection from "./sections/hero/hero-section";
import Clip1 from "./sections/clip1";
import PortfolioSection from "./sections/portfolio";
import AssetsSection from "./sections/assets";
import AllCoinSection from "./sections/all-coin";
import CategorySection from "./sections/category";
import PricingSection from "./sections/pricing";
import WelcomeModal from "component/Modal/WelcomeModal";



export const Home = () => {
    const [welcomePop,setWelcomePop]=useState(false);
    useEffect(()=>{
        setWelcomePop(true);
    },[])
    return (
        <>
        {welcomePop && <WelcomeModal handleClose={setWelcomePop} />} 
        <Box sx={{ bgcolor: '#000', paddingTop: '100px', }}>
             
            <HeroSection />
            {/* <Clip1 /> */}
            <Box>
                <PortfolioSection />
            </Box> 
            <CategorySection />
            <AssetsSection />
            <AllCoinSection />
        </Box>
        </>

    )
}
export default Home;

// export const Home=()=>{

// return(
    
// <div id="main" class="main-content-wraper">
//     <div class="main-content-inner">

       

      
//         <section id="hero" class="section-lg section-hero hero-slider-section-one" style={{background: "url('img/hero-section-bg-2.jpg')no-repeat center center / cover"}}>
//             <div class="container">
//                 <div class="row">
//                     <div class="col-md-6">
//                         <div class="hero-content-wrap">
//                             <div class="hero-content typed-strings">
//                                 <h1>XGain Provide - <br/><span class="typed"></span></h1>
//                                 <p>Our software platform and custom development allow healthcare institutions to create comprehensive cross-platform solutions that ensure positive patient experiences.</p>
//                                 <div class="slider-action-btn">
//                                     <a href="#" class="btn btn-icon"><i class="fa fa-apple"></i></a>
//                                     <a href="#" class="btn btn-icon"><i class="fa fa-android"></i></a>
//                                     <a href="#" class="btn btn-icon"><i class="fa fa-windows"></i></a>
//                                     <a href="https://www.youtube.com/watch?v=LV3cjaA7NtE" class="video">
//                                         <span class="ti-control-play"></span>
//                                     </a>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div class="col-md-6">
//                         <div class="hero-image">
//                             <img src="img/faq-right.png" alt="hero-image" class="img-responsive"/>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div class="section-shape">
//                 <img src="img/shape-bg.png" alt="shape image"/>
//             </div>
//         </section>
      

//         {/* <footer class="footer-section bg-secondary ptb-60">
//             <div class="footer-wrap">
//                 <div class="container">
//                     <div class="row">
//                         <div class="col-md-6 col-md-offset-3">
//                             <div class="footer-single-col text-center">
//                                 <img src="img/logo-color.png" alt="footer logo"/>
//                                 <div class="footer-social-list">
//                                     <ul class="list-inline">
//                                         <li><a href="#"> <i class="fa fa-facebook"></i></a></li>
//                                         <li><a href="#"> <i class="fa fa-twitter"></i></a></li>
//                                         <li><a href="#"> <i class="fa fa-linkedin"></i></a></li>
//                                         <li><a href="#"> <i class="fa fa-google-plus"></i></a></li>
//                                         <li><a href="#"> <i class="fa fa-youtube"></i></a></li>
//                                     </ul>
//                                 </div>
//                                 <div class="copyright-text">
//                                     <p>&copy; copyright <a href="#">XGain</a> Software Template By <a
//                                             href="https://themeforest.net/user/htmllover/portfolio" target="_blank">HtmlLover</a>
//                                     </p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </footer> */}
//     </div>
// </div>
// )

// }

// export default Home;