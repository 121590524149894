import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CustomInput from 'component/Input/CustomInputDark';
import CryptoInput from 'component/Input/CryptoInput';
import CustomeSelect from 'component/Select';
import { addCrypto } from 'api/crypto';

import { set_message } from 'redux/reducer/message';
import { getATH_ATL,getCategory } from 'api/crypto';
import FormateCurrency from 'utils/FormateCurrency';
import { useSelector,useDispatch } from 'react-redux';
import { mycoin } from 'api/crypto';
import { set_crypto } from 'redux/reducer/crypto';
import FormatToIntCurrency from 'utils/FormatToIntCurrency';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 410,
    width:'90%',
    margin:'auto',
    bgcolor: 'primary.light',
    boxShadow: 24,
    color: 'common.white',
    p: 1.8,
    borderRadius: 3,
    marginBottom: 50,
};





export default function AddCrypto({ refresh, visible, setVisble, selectedCoin, setSelectedCoin,setQuery }) {
    const [open, setOpen] = React.useState(visible);
    const [data, setData] = React.useState({ ATH: "", ATL: '',symbol:selectedCoin,qty:"",avg_buy:"" });
    const [category, setCategory] = React.useState(null);
    const [key, setkey] = React.useState(0);
    const [err, setErr] = React.useState(null);
    const dispatch = useDispatch();
    const { plan } = useSelector(state => state.user);
    const categories=useSelector(state=>state.crypto.category)

    React.useEffect(() => {
        setOpen(visible);
    }, [visible]);

    React.useEffect(() => {
        setErr(null);
    }, [data.symbol])

    const handleClose = () => {
        setOpen(false);
        setVisble(false);
        setErr(null)
        setkey(Math.random())
        if (setSelectedCoin) {
            setSelectedCoin(null);
        }
    }

    const handleChange = (e) => {
        if(['category','symbol'].includes(e.target.name)){
            setData(prev => ({ ...prev, [e.target.name]: e.target.value }));
        }else{
           if(!isNaN(parseFloat(e.target.value))){
            setData(prev => ({ ...prev, [e.target.name]: e.target.value }));
           }
          return e.preventDefault();
        }
       
    }

   
    const fetchData =React.useCallback(async () => {
        mycoin()
            .then(res => {
                //setCrypto(res.data?.data?.[0]?.assets||[])
                dispatch(set_crypto(res.data?.data?.[0]?.assets||[]))
               // res.data.total_data[0]&&setTotal(res.data.total_data[0])
            })
    }, [])

    const handleSubmit = (e) => {
        let is_adding_another=e.nativeEvent.submitter.id=='add-another'
        e.preventDefault();
        if(selectedCoin){
            data.symbol=selectedCoin;
        }
        if(plan&& plan.name!=='Pro'){
           data.category='Goldv2';
        }
        addCrypto(data)
            .then(res => {

                dispatch(set_message('Coin added'))
                if(!is_adding_another){
                    setVisble(false);
                }
               
                setData({});
                setkey(Math.random())
                if(setQuery){
                    setQuery('')
                }
                if(!is_adding_another){
                    setTimeout(() => {
                        if(window.location.pathname.includes('main')){
                            return fetchData();
                        }
                        refresh ? refresh() :(window.location.pathname.includes('all-coin')|| window.location.reload());
                    }, 2000)
                }
            })
            .catch(err => {
                setErr(err?.response?.data?.errorMessage?.[0] ||
                    err?.response?.data?.msg || 'Ooops! Something went wrong!')
            })
    }

    React.useEffect(()=>{
        if(plan&& plan.name!=='Pro'){
            setData(prev=>({...prev,category:'Goldv2'}))
        }
    },[plan])


    React.useEffect(()=>{
        if(selectedCoin){
            Update_ATL_ATH(selectedCoin)
            getCategory(selectedCoin)
            .then((res)=>{
                setCategory(res.data.category);
                setData(prev=>({...prev,category:res.data.category}))
            })
        }
    },[selectedCoin])


    const Update_ATL_ATH=(symbol)=>{
        getATH_ATL(symbol)
        .then(res=>{
          const {ATH,ATL,PRICE}=res.data;
        //   if(!ATH || !ATL){
        //     setShowExtra(true);
        //   }
          if(!PRICE){
            setErr('Sorry we are not able to receive info for this coin')
          }
          setData(prev=>({...prev,ATH:ATH||'',ATL:ATL||'',PRICE}))
        })
      }
    

    return (
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box key={key} component='form' onSubmit={handleSubmit} sx={style}>
                <Box sx={{ marginBottom: 1, marginTop: 2, textAlign: 'center' }}>
                      <Box sx={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                            {selectedCoin && <><img src={`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/64/${selectedCoin?.toLowerCase()}.png`} /> <br/><br/></>} 
                            <Typography sx={{color:'#fff',ml:2}} variant='h6'>ADD {selectedCoin?.replaceAll('_', '')}</Typography>
                            <Typography sx={{color:'#fff',mt:2}}>{data.price?`Current Price : ${FormatToIntCurrency(data?.price)}`:""}</Typography>
                            {/* <Typography sx={{color:'#fff',ml:2}} variant='h8'>Currently trading around {data?.PRICE && FormateCurrency(data.PRICE)} </Typography>
                            <Typography sx={{color:'#fff',ml:2}} variant='h8'>Currently trading around {data?.PRICE && FormateCurrency(data.PRICE)} </Typography> */}
                     
                     </Box>
                </Box>
                <Box>
                    {err && <Typography sx={{ color: 'red', textAlign: 'center',mb:2 }}>{err}</Typography>}
                    {!selectedCoin  &&
                     
                        <CryptoInput defaultCoin={selectedCoin} setData={setData} sx={{ mt: 2 }} />
                    }
                    <CustomInput onChange={handleChange} inputMode="numeric" value={data.qty} name='qty' sx={{ mt: 2 }} label='Quantity' />
                    <CustomInput onChange={handleChange} inputMode="numeric" value={data.avg_buy} name='avg_buy' sx={{ mt: 2 }} label='Average Buy' />
                   {plan?.name=='Pro' &&
                    <CustomeSelect
                    key={Math.random()}
                       onChange={handleChange}
                       value={data.category||category}
                       readOnly={category?true:false}
                       name='category'
                       label='Category*'
                       customLabel={true}
                       option={categories.map(({name,_id})=>({value:name, label:name}))}
                   />
                   }
                </Box>
                <Box sx={{ width: '100%', height: 1.1, background: '#fff', marginTop: 3 }} />
                <Box sx={{ paddingTop: 3 ,fontSize:10,justifyContent:'space-between',display:'flex'}}>
                   {!selectedCoin &&  <Button id='add-another' type='submit' sx={{ color: '#fff', borderColor: "#fff", borderRadius: '20px',textTransform:'capitalize' }} variant="outlined">Save & Add</Button>}
                    <Box sx={{ml:'auto'}}>
                    <Button onClick={handleClose} sx={{ color: '#fff', borderRadius: '20px', mr: 1,textTransform:'capitalize' }}  variant="outlined">Cancel</Button>
                    <Button type='submit' sx={{ color: '#fff', borderColor: "#fff", borderRadius: '20px',textTransform:'capitalize'}} variant="outlined">Save</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}


// sx={{border:"1px solid #fff"}} labelStyle={{background:'black',padding:'0px 5px'}} style={{color:'#fff'}} 