import React, { Component, PureComponent } from 'react';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
// import ValueViewer from './ValueViewer';
import { Box } from '@mui/material';
import { SliderRail, Handle, Track, Tick } from './SliderHelper';

const sliderStyle={
  position: 'relative',
  height: '100%',
  marginLeft: '90%',
  touchAction: 'none',
  color:'#293143'
};

function compareDecimals(a, b) {
  if (a === b) 
       return 0;

  return a < b ? -1 : 1;
}


export default class FibSlider extends Component {
    constructor(props){
        super(props);
       

        this.state={
            values:this.props.values.map((num)=>isNaN(num) || !isFinite(num)?0:num),
            domain:this.props.domain||[],
          };
    }


 
  onChange = (values) => {
    this.setState({values:values.sort(compareDecimals)});
    this.props.setPrice(values.sort(compareDecimals));
  };

  render() {
    const {
      state: { values, update },
    } = this;

    return (
      <Box sx={{ height:'100%',width:"20px",left:'-20px',position:'relative'}}>
        <Slider
          vertical
          mode={1}
          step={(this.state.domain[1]-this.state.domain[0])/100000}
          domain={this.state.domain}
          rootStyle={sliderStyle}
          onChange={this.onChange}
          values={values}
          reversed={true}
        >
          <Rail>
            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
          </Rail>
          <Handles  >
            {({ handles, getHandleProps }) => (
              <Box sx={{marginLeft:{xs:'-100px',sm:"0"},...this.props.style}} className="slider-handles">
                {handles.map(handle => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={this.state.domain}
                    getHandleProps={getHandleProps}
                    hideSliderNumber={this.props.hideSliderNumber}
                    width={this.props.dotWidth}
                    height={this.props.dotHeight}
                  />
                ))}
              </Box>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks count={10}>
            {({ ticks }) => (
              <div className="slider-ticks">
                {ticks.map(tick => (
                  <Tick key={tick.id} tick={tick} />
                  
                ))}
              </div>
            )}
          </Ticks>
        </Slider>
      </Box>
    );
  }
}

