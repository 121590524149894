import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { Box,Button,Select,MenuItem,Menu, Typography ,FormControlLabel,Checkbox, Radio} from '@mui/material';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import ExchangeIcon from '../Exchanges/ExcangeIcon';

export const CoinTable = ({ headers,apiData, rows, setPage, page,SetRowIndex,marginBottom=5 ,setCurrentExchanges ,currentExchanges,min,max,setMin,setMax,setFilter,total}) => {
 

  const counts=React.useMemo(()=>{
    let counts={LgCap:0,MidCap:0,LowCap:0,McrCap:0}
    apiData.map((obj)=>{
      if(!obj.rate && !obj.price)return;
      if(obj.cap>=1000000000){
        counts.LgCap++
      }else if(obj.cap<=1000000000 && obj.cap>=250000000){
        counts.MidCap++
      }else if(obj.cap<=250000000 && obj.cap>=50000000){
        counts.LowCap++
      }else if(obj.cap<=50000000){
        counts.McrCap++
      }
    })
    return  counts
  },[apiData])

  return (
    <>
      <Box sx={{display:{xs:'block',md:'flex'},width:'100%',justifyContent:'space-between',mt:1,alignItems:'center',px:1}}>
        <Typography sx={{color:"#fff",ml:2}}>Filters</Typography>
        <Box sx={{maxWidth:'100%',display:'flex',flexWrap:'wrap',justifyContent:'space-evenly'}}>
              <FormControlLabel
                label={`LgCap (${counts.LgCap})`}
                sx={{color:"#fff"}}
                control={
                  <Radio
                    checked={min==1000000000}
                    style={{color:"#fff"}}
                    name='LgCap'
                    onChange={setFilter}
                  />
                }
              />
              <FormControlLabel
                label={`MidCap (${counts.MidCap})`}
                sx={{color:"#fff"}}
                control={
                  <Radio
                    checked={min==250000000}
                    name='MidCap'
                    style={{color:"#fff"}}
                    onChange={setFilter}
                  />
                }
              />
              <FormControlLabel
                label={`LowCap (${counts.LowCap})`}
                sx={{color:"#fff"}}
                control={
                  <Radio
                    style={{color:"#fff"}}
                    checked={min==50000000}
                    name='LowCap'
                    onChange={setFilter}
                  />
                  
                  
                }
              />

              <FormControlLabel
                label={`McrCap (${counts.McrCap})`}
                sx={{color:"#fff"}}
                control={
                  <Radio
                    checked={max==50000000}
                    style={{color:"#fff"}}
                    name='McrCap'
                    onChange={setFilter}
                  />    
                }
              />


        </Box>
        <Box sx={{w:'150px'}}>
           <Button onClick={()=>{setPage(0); setCurrentExchanges('')}} style={{w:5,h:5,display:'inline-grid',placeContent:'center'}} sx={{m:0,minWidth:0,mx:'2px',bgcolor:''==currentExchanges?"primary.light":''}}>
            <Typography sx={{color:"#fff",fontWeight:'bold',mr:1,fontSize:15}}>All</Typography> 
           </Button>
          {['Coinbase','Binance','KuCoin','MexC', 'Kraken', 'OKX','ByBit','BitMart', 'Bitget' ].map((name)=>{
            return(
               <Button onClick={()=>{setPage(0); setCurrentExchanges(name)}} style={{w:5,h:5,display:'inline-grid',placeContent:'center'}} sx={{m:0,minWidth:0,mx:'2px',bgcolor:name==currentExchanges?"primary.light":''}}>
              <ExchangeIcon  containerMargin={0} containerHeight={25} size={25} isLink={false}  name={name}/>
              </Button>
            )
          })}
        </Box>
      </Box>
      <TableContainer elevation={0} sx={{ bgcolor: 'primary.light', color: 'common.white', marginTop: 1, marginBottom: marginBottom, height: '90vh' }} component={Paper}>
        <Table stickyHeader sx={{ minWidth: 650 }} className='all-coin-table' aria-label="simple table">
          <TableHead sx={{ bgcolor: 'primary.light' }}>
            <TableRow>
              {headers.map(obj => obj.name)}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
               classes='coin-tr'
                key={row[0].symbol}
                sx={{'td': { border: 0 ,padding:"2px"}}}
               
              >
                {row.map((data, index) => <TableCell sx={{ px: 0, color: 'common.white', bgcolor: 'primary.dark', ...data.sx }} align="center">{data.element}</TableCell>)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>

  );
}


CoinTable.defaultProps = {
  headers: [
    { name: 'Rank', hidden: true },
    { name: '', hidden: true },
    { name: '', hidden: true },
    { name: 'XGain', hidden: true },
    { name: 'Qty', hidden: true },
    { name: 'Price', hidden: true },
    { name: 'AvgBuy', hidden: true },
    { name: 'Invested', hidden: true },
    { name: 'Action', hidden: true },
  ],
  rows: []
}
