import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import styled from '@emotion/styled';
import { getATH_ATL } from 'api/crypto';
import { CircularProgress } from '@mui/material';
const CssAutoComplete = styled(Autocomplete)({
  '& label.Mui-focused': {
    color: '#fff',
  },

  "& .MuiSvgIcon-root": {
      color: "white",
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      bgcolor:'#21232D',
      borderColor: 'grey',
      color: '#fff',
    },
    '&:hover fieldset': {
      borderColor: 'grey',
      color: '#fff',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'grey',
      boxShadow: '0px 0px 3px #fff',
      color: '#fff',
    },
  },
});

export default function CryptoInput({name,setData,defaultCoin}) {
  const [value,setValue]=React.useState();
  const [suggestions, setSuggestions] = React.useState([]);
  const [loading,setLoading]=React.useState(false)
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  React.useEffect(()=>{
    if(defaultCoin){
     Update_ATL_ATH(defaultCoin)
    }
  },[defaultCoin])

  const handleChange = (value) => {
   setLoading(true)
    fetch(`/api/v1/crypto/get-matching-coin?symbol=${value}`)
      .then((res) => res.json())
      .then((json) => {
        setSuggestions(json);
        setLoading(false);
      });
  };
  const optimizedFn = React.useCallback((e)=>{
   if(e.target.value.length<3)return;
    debounce(handleChange(e.target.value))
  } , []);

  const Update_ATL_ATH=(symbol)=>{
    getATH_ATL(symbol)
    .then(res=>{
      const {ATH,ATL}=res.data;
      setData(prev=>({...prev,ATH:ATH||0,ATL:ATL||0}))
    })
  }

  return (
    <CssAutoComplete
      options={suggestions}
      autoHighlight
      label='Symbol'
      value={value}
      onChange={(event, newValue) => {
       setValue(newValue)
       setData(prev=>({...prev,symbol:newValue?.code,price:newValue?.rate}))
       Update_ATL_ATH(newValue?.code)
      }}
      loading={loading}
      getOptionLabel={(option) => option.code}
      InputProps={{ sx: { height: 50, fontSize: 18 ,color:"#fff"} }}
      inputProps={{ style: { fontSize: 18, color: "#fff" } }} // font size of input text
      InputLabelProps={{ style: { fontSize: 18, color: '#fff' } }} // font size of input label
      renderOption={(props, option) => 
        (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={option.webp32}
            srcSet={option.webp64}
            alt=""
          />
          {option.rank} {option.code} {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={optimizedFn}
          label="Symbol"
          sx={{bgcolor:'#21232D'}}
          InputLabelProps={{ style: { fontSize: 18,color:'#fff'} }} // font size of input label
          inputProps={{...params.inputProps,maxLength:20, autoComplete: 'new-password',
          style: { fontSize: 18, color: "#fff" } }} // font size of input text
        />
      )}
    />
  );
}

